<template>
<section>
    <v-container>
        <v-row justify="start">
          <v-toolbar-title
            class="grey--text text--darken-4 font-weight-black my-5 ml-12"
            >
            Medication List
          </v-toolbar-title>
           <v-spacer />
          <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
          >
          </v-text-field>
           <v-spacer />
          <div class="text-center">
            <v-menu bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  append-icon="filter_alt"
                  v-model="searchResident"
                  label="Filter By Resident"
                  single-line
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                  />
              </template>
              <v-list style="max-height: 100px" v-model="searchResident">
                <v-list-item
                  v-for="(item, index) in residents"
                  :key="index"
                  v-bind="item"
                >
                  <v-list-item-title  @click="medicationByResident(item)">{{ item.fullName }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="medications"
          :options.sync="options"
          :server-items-length="totalMedications"
          :loading="loading"
          loading-text="Loading ..... Please wait"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 20, 40],
            itemsPerPageText: 'Medications per page',
          }"
          update:options
          class="elevation-23"
        >
            <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-dialog v-model="dialog" max-width="500px">

                      <v-card>
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.name" label="Medication name" @click="sortBy(header.value)"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.directive" label="Directive"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4">
                                <v-text-field v-model="editedItem.description" label="Description"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6" md="4" class="mr-12 mb-5">
                                <label for="quantity-order"> Quantity Order </label><br>
                                <input v-model="editedItem.quantity.order" type="number" name="quantity-order" id="input"/>
                              </v-col>
                              <v-col cols="12" sm="6" md="4" class="ml-12 pl-2 mb-5">
                                 <label for="quantity-receive"> Quantity Receive </label><br>
                                <input v-model="editedItem.quantity.receive" type="number" name="quantity-receive" id="input"/>
                              </v-col>
                              <v-row>
                                  <v-col cols="12" sm="6" md="5">
                                        <v-menu
                                          v-model="editedItem.menu1"
                                          :close-on-content-click="false"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="290px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="editedItem.startDate"
                                              label="Start Date"
                                              prepend-icon="event"
                                              readonly
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-datetime-picker
                                            v-model="editedItem.startDate" timePickerFormat="24hr"
                                            dateFormat="dd-MM-yyyy">
                                          </v-datetime-picker>
                                        </v-menu>
                                      </v-col>

                                      <v-col cols="12" sm="6" md="5" class="ml-10">
                                        <v-menu
                                          v-model="editedItem.menu2"
                                          :close-on-content-click="false"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="200px"
                                        >
                                          <template v-slot:activator="{ on }">
                                            <v-text-field
                                              v-model="editedItem.endDate"
                                              label="End Date"
                                              prepend-icon="event"
                                              readonly
                                              v-on="on"
                                            ></v-text-field>
                                          </template>
                                          <v-datetime-picker no-title scrollable
                                            v-model="editedItem.endDate" timePickerFormat="24hr"
                                            dateFormat="dd-MM-yyyy">
                                          </v-datetime-picker>
                                        </v-menu>
                                      </v-col>
                                </v-row>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text  @click="close">Cancel</v-btn>
                          <v-btn color="blue darken-1" text  @click="save">Save</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-row justify="space-around">
                      <span class="group pa-2">
                          <v-icon
                            v-if="$can('update')"
                            small
                            class="mr-2"
                            @click="editItem(item)"
                          >
                            edit
                          </v-icon>
                          <v-icon
                            v-if="$can('delete')"
                            small
                            @click="deleteItem(item)"
                          >
                            delete
                          </v-icon>
                            <v-icon v-if="$can('read')" medium @click="detail_medication(item)">info</v-icon>
                      </span>
                  </v-row>
                </template>
        </v-data-table>
         <div class="table-footer-prepend d-flex pl-2 align-center"  v-if='selectedId'>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                class="mr-3"
                v-on="on"
                @click="addNew()"
              >
              Register
              </v-btn>
            </template>
            <span>Register new medication</span>
          </v-tooltip>
         </div>
    </v-container>
</section>
</template>
<script>
import {  mapActions } from 'vuex'
import medicationApi from '../../services/api/MedicationApi';
import residentApi from '@/services/api/ResidentApi';
import moment from 'moment';
export default {
    name: 'medication',
    components: {
    },
    data () {
        return {
            dialog: false,
            medications: [],
            residents: [],
            totalMedications: 0,
            loading: true,
            options: {},
            search: '',
            searchResident: '',
            selectedId:'',
             headers: [
                { text: 'Medication Name', value: 'name', class: 'success--text font-weight-bold' },
                { text: 'Resident', value: 'resident.fullName',class: 'success--text font-weight-bold' },
                { text: 'Description', value: 'description', sortable: false, class: 'success--text font-weight-bold'  },
                { text: 'Receive Time', value: 'receiveTime', class: 'success--text font-weight-bold' },
                { text: 'Quantity Order', value: 'quantity.order', class: 'success--text font-weight-bold' },
                { text: 'Quantity Received', value: 'quantity.receive', class: 'success--text font-weight-bold' },
                { text: 'Quantity Remaining', value: 'quantity.remaining', class: 'success--text font-weight-bold' },
                { text: 'Actions', value: 'action', sortable: false, class: 'success--text font-weight-bold', width: '7rem' },
            ],
              editedItem: {
                name: '',
                directive: '',
                description: '',
                quantity: {
                  order: null,
                  receive: null,
                },
                startDate: null,
                endDate: null,
                menu1: false,
                menu2: false,
              },
              defaultItem: {
                name: '',
                description: '',
                receiveTime: '',
                quantity: {
                  order: null,
                  receive: null,
                },
              },
        }
    },
    methods: {
        ...mapActions([
            'update_medication',
        ]),
        editItem (item) {
            this.editedItem = Object.assign({}, item)
            this.defaultItem.startDate = item.startDate;
            this.defaultItem.endDate = item.endDate;
            this.dialog = true
        },
        deleteItem (item) {
          confirm('Are you sure you want to delete this medication?') && this.delete_medication(item)
          const index = this.medications.indexOf(item)
          this.medications.splice(index, 1)
        },

        close () {
          this.dialog = false
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          }, 300)
        },
        save () {
            delete this.editedItem.resident;
            delete this.editedItem.createdOn;
            delete this.editedItem.modifiedOn;
            delete this.editedItem.receiveTime;
            delete this.editedItem.quantity.value;
            delete this.editedItem.user;
            if (this.defaultItem.startDate != this.editedItem.startDate){
               this.editedItem.startDate = this.formatDate(this.editedItem.startDate);
            }
            if (this.defaultItem.endDate != this.editedItem.endDate){
              this.editedItem.endDate=this.formatDate(this.editedItem.endDate);
            }
            this.update_medication({...this.editedItem})
          this.close()
        },
        get_registered_medication_list () {
          this.loading = true
          return new Promise((resolve, reject) => {
              const { sortBy, sortDesc, page, itemsPerPage } = this.options
              // console.log(sortDesc);
              if (sortDesc&& sortDesc.length && sortDesc[0] == false){
                var orde = 'asc'
              }
              else{
                 orde = 'desc'
              }
              if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                var homeId = ''
              }
              else{
                homeId = localStorage.getItem('homeId')
              }
              medicationApi.getAllMedication({page: page, count:itemsPerPage, id:'', q:'', orderBy: sortBy && sortBy[0], order: orde})
                .then(medications => {
                    if (homeId.toString().length > 0 ){
                        this.medications = medications.result.filter(medication => medication.resident.home.homeId==homeId)
                        this.totalMedications = this.medications.length
                    }
                    else{
                        this.medications = medications.result
                        this.totalMedications = medications.count
                    }
                })
                .catch(error => {
                    return reject(error)
                })

              setTimeout(() => {
                this.loading = false
                }, 1000)
          })
        },
        delete_medication(item) {
            medicationApi.deleteRegisteredMedication(item.id)
                .then(medication => {
                    return ("deleted" + medication);
                })
        },
        detail_medication(medication){
          this.$router.push({ name: 'medicationDetail', params: {medicationId:medication.id, residentId:medication.resident.id}})
        },
        formatDate(date) {
          return moment(date).format('DD-MM-YYYY HH:mm')
      },
      get_resident_list () {
          // this.loading = true
          // if(this.searchResident.length>0){
              return new Promise((resolve, reject) => {
                  if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homecode = ''
                  }
                  else{
                    homecode = localStorage.getItem('homeCode')
                  }
                  residentApi.getResidents({page: '', count:'', home:homecode, q:''})
                    .then(residents => {
                        this.residents = residents.result
                        this.totalResidents = residents.count
                    }).catch(error => {
                        return reject(error)
                    })
                  setTimeout(() => {
                    this.loading = false
                    }, 1000)
              })
        },
      medicationByResident(resident) {
          var id = resident.id;
          this.selectedId = resident.id;
          this.searchResident = resident.fullName
          return new Promise((resolve, reject) => {
                  medicationApi.getRegisteredMedication({page:'', count:'', id:id, q:''})
                    .then(medications => {
                      this.medications = medications.result
                      this.totalMedications = medications.count
                    })
                    .catch(error => {
                      return reject(error)
                    })
                })
      },
      addNew () {
        this.$router.push({ name: 'registerMedication', params: {residentId:this.selectedId}});
      },
    },
    created() {
        this.get_registered_medication_list();
    },
    beforeMount(){
      this.get_resident_list();
    },
    computed:{
       filteredCustomers:function(){
        var self=this;
        return this.residents.filter(function(cust){return cust.fullName.toLowerCase().indexOf(self.searchResident.toLowerCase())>=0;});
        }
    },
    watch: {
        options: {
          handler () {
            this.get_registered_medication_list()
                .then(data => {
                    this.medications = data.result
                    this.totalMedications = data.count
                })
          },
          deep: true,
        },
        dialog (val) {
          val || this.close()
        },
        editedItem: function() {
          this.get_registered_medication_list()
                .then(data => {
                    this.medications = data.result
                    this.totalMedications = data.count
                })
        },
        search: function() {
          this.searchResident = ''
          this.selectedId = ''
          if(this.search.length > 0){
            try{
                const medication = this.medications.find(x => (x.resident.fullName === this.search||x.resident.firstName === this.search));
                const id = medication.resident.id;
              if (id){
                return new Promise((resolve, reject) => {
                  medicationApi.getRegisteredMedication({page:'', count:'', id:id, q:''})
                    .then(medications => {
                      this.medications = medications.result
                      this.totalMedications = medications.count
                    })
                    .catch(error => {
                      return reject(error)
                    })
                })
                }
              }
            catch(err){
                return new Promise((resolve, reject) => {
                  if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homeId = ''
                  }
                  else{
                    homeId = localStorage.getItem('homeId')
                  }
                  medicationApi.getRegisteredMedication({page:'', count:'', id:'', q:this.search})
                    .then(medications => {
                      if (homeId.toString().length > 0 ){
                        this.medications = medications.result.filter(medication => medication.resident.home.homeId==homeId)
                        this.totalMedications = this.medications.length
                      }
                      else{
                        this.medications = medications.result
                        this.totalMedications = medications.count
                      }
                    })
                    .catch(error => {
                      return reject(error)
                    })
                })
             }
          }
          if(this.search.length <= 0){
            this.get_registered_medication_list()
                .then(data => {
                    this.medications = data.result
                    this.totalMedications = data.count
                })
          }
        },
        searchResident: function(){
            if(this.searchResident.length <= 0){
              this.selectedId = ''
            this.get_registered_medication_list()
                .then(data => {
                    this.medications = data.result
                    this.totalMedications = data.count
                })
            this.get_resident_list();
            }
            else if(this.searchResident.length >0){
              return new Promise((resolve, reject) => {
                  if (['Admin', 'Administrator', 'Director', 'Supervisor', 'CCSO', 'CEO'].includes(localStorage.getItem('role'))){
                    var homecode = ''
                  }
                  else{
                    homecode = localStorage.getItem('homeCode')
                  }
                  residentApi.getResidents({page:'', count:'', home:homecode, q:this.searchResident})
                    .then(residents => {
                        this.residents = residents.result
                        this.totalResidents = residents.count
                    }).catch(error => {
                        return reject(error)
                    })
                  setTimeout(() => {
                    this.loading = false
                    }, 1000)
              })
          }
        },
    },
}
</script>
<style lang="scss">
  #input {
    width:70%;
  }
  .table-footer-prepend {
    margin-top: -58px;
    height: 58px;
    margin-left: 400px;
  }
</style>
